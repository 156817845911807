.root {
  position: relative;
}

.label {
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  pointer-events: none;
  color: #7f7f7f;
  font-size: 19px;
  top: 13px;
  left: 17px;
  transition: transform 150ms ease, font-size 150ms ease, color 150ms ease;
  width: 100%;
  transform-origin: left top;
}

.label[data-floating="true"] {
  transform: translate(0, -12px) scale(0.55);
  font-weight: 700;
  color: #999999;
  padding-top: 2px;
  padding-left: 1px;
}

.label[data-error="true"] {
  color: #ff0000 !important;
}

.label[data-error="true"] > * {
  color: #ff0000 !important;
}

.label[data-floating-bg="true"] {
  transform: translate(0, -12px) scale(0.55);
  font-weight: 700;
  color: #999999;
  width: 161%;
  background-color: #fff;
  padding-top: 2px;
  padding-left: 1px;
  line-height: 1.4;
}

.required {
  transition: opacity 150ms ease;
  /*opacity: 0;*/
  /*color: #7f7f7f;*/
  color: #e31d1c;
  display: inline-block;
}

[data-floating] .required {
  opacity: 1;
}

.input::placeholder,
.innerInput::placeholder {
  transition: color 150ms ease;
  color: transparent;
}

.input[data-floating]::placeholder,
.innerInput[data-floating]::placeholder {
  color: var(--mantine-color-placeholder);
}

.input:-webkit-autofill,
.innerInput:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}
